// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails'

// import 'src/bootstrap.js'

import 'src/fontawesome.js'

// import * as bootstrap from 'bootstrap'
// window.bootstrap = bootstrap
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
// import bootstrap from 'src/bootstrap.bundle.min.js'

import LocalTime from 'local-time'

import 'chartkick/chart.js'

window.bootstrap = bootstrap
LocalTime.start()
